import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import ModalLaiaToken from './modal-laia-token.js'
import ModalTag from './modal-tag.js'
import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    ModalTag,
    FullTable,
    ModalLaiaToken
  },
  props: {
    pages: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    },
    createMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dialogLaiaToken: false,
      dialogTag: false,
      formSelected: null,
      filters: {id: [], customerId: []},
      productsSelectOptions: [],
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    defaultOrder() {
      return {currentField: 'facebookPageName', currentDir: 'asc'}
    }
  },
  mounted() {
    this.loadProducts()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.facebook.${key}`, options)
    },
    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },
    loadProducts(customerId) {
      API.products.index({filter: {customerId}, page: {number: 1, size: 1000}}).then(({data}) => {
        this.productsSelectOptions = data.map(product => ({
          label: product.name,
          value: product.id
        }))
      })
    },
    updateTags(data) {
      API.facebook.updateTags(this.formSelected.id, data).then(() => {
        this.getData()
        this.dialogTag = false
        this.$notifications.success(this.t('succesfullyUpdated'))
      })
    },
    configTag(form) {
      this.formSelected = JSON.parse(JSON.stringify(form))
      this.dialogTag = true
    },
    configCampaign(form) {
      this.formSelected = JSON.parse(JSON.stringify(form))
      this.dialogLaiaToken = true
    },
    confirmDelete(form) {
      if (this.createMode) return this.$emit('delete-event', form)
      return openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: form.facebookForm.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(form.id))
    },
    destroy(id) {
      API.facebook.destroy(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
