
import template from './modal-tag.pug'

export default Vue.extend({
  template: template(),
  props: {
    formSelected: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: {
        utmId: null,
        utmCampaign: null,
        utmSource: null,
        utmMedium: null,
        utmContent: null,
        utmTerm: null,
        productId: null
      }
    }
  },
  computed: {
    saveButtonText() {
      return this.$t('general.update')
    }
  },
  mounted() {
    this.data = {
      utmId: this.formSelected.utmId,
      utmCampaign: this.formSelected.utmCampaign,
      utmSource: this.formSelected.utmSource,
      utmMedium: this.formSelected.utmMedium,
      utmContent: this.formSelected.utmContent,
      utmTerm: this.formSelected.utmTerm,
      productId: this.formSelected.productId
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.facebook.modal-tag.${key}`, options)
    },
    cancel() {
      this.$emit('input', false)
    },
    submit() {
      this.$emit('submit', this.data)
    }
  }
})
