/* eslint-disable eqeqeq */
import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './cti.pug'

const DefaultData = () => ({
  name: '',
  config: {
    ctiId: '',
    callCenterId: '',
    serviceId: '',
    typeLead: '',
    channel: '',
    agentId: ''
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      callCenters: [],
      ctis: [],
      services: [],
      channelOptions: [
        {label: 'CTI', value: 'CTI'},
        {label: 'SMART', value: 'SMART'}
      ],
      TypeLeadOptions: [
        {
          label: 'LEAD CALIENTE',
          value: '1'
        },
        {
          label: 'LEAD TEMPLADO',
          value: '2'
        },
        {
          label: 'LEAD FRIO',
          value: '3'
        },
        {
          label: 'LEAD CALIENTE TOP',
          value: '4'
        }
      ]
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    callCenterSelectOptions() {
      return this.callCenters.map(callCenter => ({
        label: callCenter.name,
        value: callCenter.id
      }))
    },

    ctiSelectOptions() {
      return this.ctis.map(cti => ({
        label: cti.name,
        value: cti.id
      }))
    },

    serviceSelectOptions() {
      return this.services && this.services[0] && this.services.map(service => ({
        label: service.serviceId,
        value: service.serviceId
      }))
    },

    callCenter() {
      return this.callCenters.find(callCenter => (callCenter.id === this.data.config.callCenterId))
    },

    ctiService() {
      return this.ctis.find(cti => (cti.id === this.data.config.ctiId))
    },

    checkServices() {
      return !!this.services.length
    },

    valueSelectOptions() {
      const type = 'workflow'
      const options = {
        [type]: []
      }

      // agrupamos por tipo
      this.node.tags.forEach(item => {
        if (type !== item.type) return
        // if (!options[item.type]) options[item.type] = []

        options[item.type].push(
          {label: item.name || item.key, value: `$lead.${item.type}.${item.key}`}
        )
      })

      let finalOptions = []

      // cada tipo debe tener su grupo
      Object.keys(options).sort().forEach(type => {
        finalOptions.push({title: this.$t(`campaigns.show.tags.types.${type}`)})
        finalOptions = finalOptions.concat(this.$sortByLocale(options[type], 'label'))
      })

      return finalOptions
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
        this.getCallCenters()
      }
    },

    callCenter: {
      handler(newValue) {
        if (newValue) this.getCtis()
      }
    },

    ctiService: {
      handler(newValue) {
        if (newValue) this.getCtiServices()
      }
    }
  },
  mounted() {
    if (!this.data.config.channel) {
      this.data.config.channel = 'CTI'
    }
  },
  methods: {
    getCallCenters() {
      const callCentersId = this.campaign.callCenters.map(callCenter => (callCenter.id))

      return API.callCenters.index({filter: {id: callCentersId}}).then(({data}) => {
        this.callCenters = this.$sortByLocale(data, 'name')
      })
    },

    getCtis() {
      // buscamos los CTI del callCenter configurados para la campaña
      const ctiIds = []
      this.campaign.callCenters.forEach(item => {
        if (item.id === this.callCenter.id) ctiIds.push(item.ctiId)
      })

      return API.ctis.index(
        {filter: {id: ctiIds}, page: {number: 1, size: 1000}}, {skipLoading: true}
      ).then(({data}) => {
        this.ctis = this.$sortByLocale(data, 'name')
        this.data.config.ctiId = this.data.config.ctiId ? this.data.config.ctiId : this.ctis[0].id
      })
    },

    getCtiServices() {
      // console.log(this.data.config.callCenterId)
      // console.log(this.data.config.ctiId)

      // eslint-disable-next-line max-len
      return API.callCenters.ctiServices.index(this.data.config.callCenterId, this.data.config.ctiId).then(({data}) => {
        this.services = data
        // eslint-disable-next-line max-len
        this.data.config.serviceId = this.data.config.serviceId ? this.data.config.serviceId : this.services && this.services[0] && this.services[0].serviceId
        const typelead = this.data.config.typeLead
        // console.log(typelead);
        if (this.data.config.typeLead == undefined) {
          this.data.config.typeLead = '1'
        } else if (this.data.config.typeLead == '') {
          this.data.config.typeLead = '1'
        } else {
          this.data.config.typeLead = typelead
        }
      })
    },

    save() {
      validatePresence(this.data, [
        'name',
        'config.ctiId',
        'config.callCenterId',
        'config.serviceId',
        'config.channel'
      ])

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.cti.${key}`, options)
    }
  }
})
