export default {
  errorsRootKey: 'facebook',
  resource: 'facebook',
  actions: ['index', 'show', 'create', 'update', 'patch', 'destroy'],
  updateTags(id, data, options = {}) {
    return Vue.http.put(
      `facebook/${id}/update-tags`,
      data,
      {options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  }
}
